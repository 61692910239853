import { AxiosResponse } from 'axios';
import { MenuItemProps } from '../models/menu-item';
import GenericApi from 'root-resources/generic-api';
import URL from 'root-resources/URL';
import Resposta from 'root-models/respostaAPI';

/**
 * API de Configuração de Menu
 *
 * @author Marcos Davi <marcos.davi@bubo.tech>
 * @extends {GenericApi}
 */
class ConfigMenuAPI extends GenericApi<MenuItemProps> {
  public constructor() {
    super({ baseEndpoint: '/menu' });
  }

  public findRootMenuItems(): Promise<AxiosResponse<Resposta<MenuItemProps>>> {
    return this.api.get('/');
  }

  public findModuleChildren(id: string): Promise<AxiosResponse<MenuItemProps>> {
    return this.api.get(`/${id}`);
  }
}

export default ConfigMenuAPI;
