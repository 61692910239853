/**
 * Enum de keys das telas do sistema para diferenciar o componente CardFilters de cada uma
 *
 * @author Marcos Davi
 */
enum ScreensKeysEnum {
  USUARIOS = 'USUARIOS',
  EMPRESA = 'EMPRESA',
  GRUPO_USUARIO = 'GRUPO_USUARIO',
  GRUPO_ECONOMICO = 'GRUPO_ECONOMICO',
  MENU = 'MENU',
}

export default ScreensKeysEnum;
