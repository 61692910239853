import Usuarios from 'root-views/usuario/usuarios';
import EditarUsuarios from 'root-views/usuario/editar-usuario';
import Dashboard from 'root-views/dashboard/dashboard';
import Empresas from './empresa/empresa';
import EditarEmpresa from './empresa/editar-empresa';
import GrupoUsuario from './grupo-usuarios/grupo-usuarios';
import EditarGrupoUsuario from './grupo-usuarios/editar-grupo-usuarios';
import GrupoEconomicos from './grupo-economico/grupo-economico';
import EditarGrupoEconomico from './grupo-economico/editar-grupo-economico';
import Configuracao from './configuracao/view/configuracao';
import FirebaseConfig from './firebaseconfig/view/firebase-config';
import SideMenuConfig from 'root-views/configmenu/pages/config-menu';
import ListingMenu from './configmenu/pages/listing-menu';

export type ScreenRoutesType = {
  path: string;
  exactPath?: boolean;
  component: any;
};

/**
 * Constante com as definições das rotas das Screens/Views/Telas da aplicação
 */
const viewsRoutes: ScreenRoutesType[] = [
  {
    path: '/',
    exactPath: true,
    component: Dashboard
  },
  {
    path: '/usuario',
    exactPath: true,
    component: Usuarios
  },
  {
    path: '/usuario/editar/:id',
    component: EditarUsuarios
  },
  {
    path: '/usuario/novo',
    component: EditarUsuarios
  },
  {
    path: '/empresa',
    exactPath: true,
    component: Empresas
  },
  {
    path: '/empresa/editar/:id',
    component: EditarEmpresa
  },
  {
    path: '/empresa/novo',
    component: EditarEmpresa
  },
  {
    path: '/grupo-usuario',
    exactPath: true,
    component: GrupoUsuario
  },
  {
    path: '/grupo-usuario/editar/:id',
    component: EditarGrupoUsuario
  },
  {
    path: '/grupo-usuario/novo',
    component: EditarGrupoUsuario
  },
  {
    path: '/grupo-economico',
    exactPath: true,
    component: GrupoEconomicos
  },
  {
    path: '/grupo-economico/editar/:id',
    component: EditarGrupoEconomico
  },
  {
    path: '/grupo-economico/novo',
    component: EditarGrupoEconomico
  },
  {
    path: '/configuracao',
    component: Configuracao
  },
  {
    path: '/firebase-configuracao',
    component: FirebaseConfig
  },
  {
    path: '/desenvolvimento/menu/crud',
    component: SideMenuConfig
  },
  {
    path: '/desenvolvimento/menu',
    component: ListingMenu
  }
];

export default viewsRoutes;
