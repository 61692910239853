import React, { Fragment, useEffect, useState } from 'react';

import { Grid, TextFieldProps } from '@mui/material';
import { Button, Select, TextField } from '@bubotech/sumora-react-components';
import CurrentPath from './current-link';

import { FormikProps } from 'formik';
import { useStyles } from 'root-views/app.styles';

import ConfigureMenuController from '../../controller/config-menu.controller';

import { MenuItemProps } from '../../models/menu-item';

export type SelectOptions = {
  label: string;
  value: string;
};

type MenuFormProps = {
  form: FormikProps<MenuItemProps>;
  formKey: number;
  controller: ConfigureMenuController;
  itemPath: [SelectOptions[], React.Dispatch<React.SetStateAction<SelectOptions[]>>];
};

/**
 * Formulário de cadastro de novo item do menu
 *
 * @author Marcos Davi <marcos.davi@bubo.tech>
 */
function MenuForm({ form, formKey, controller, itemPath }: MenuFormProps): JSX.Element {
  const classes = useStyles();

  const [currentPathOptions, setCurrentPathOptions] = useState<MenuItemProps[]>();

  const [currentPath, setCurrentPath] = itemPath;

  function getFieldProps(field: keyof MenuItemProps): TextFieldProps {
    return {
      name: field,
      value: form.values[field],
      error: Boolean(form.errors[field] && form.touched[field]),
      helperText: Boolean(form.errors[field] && form.touched[field]) ? form.errors[field] : '',
      onBlur: form.handleBlur,
      onChange: handleChange,
    };
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name, value } = event.target;

    form.setFieldValue(name, value);
  }

  function handleSelectPath(opt: SelectOptions) {
    setCurrentPath((prev) => [...prev, opt]);

    updateOptions(opt);
  }

  async function updateOptions(opt: SelectOptions) {
    await controller.fetchMenuItemChildren(opt.value);
    const currentOptions = controller.getModule(opt.value);

    setCurrentPathOptions(currentOptions?.menuList || []);
  }

  function convertPathToSelectOptions(): SelectOptions[] {
    if (currentPathOptions) {
      return currentPathOptions.map((item) => ({ label: item.nmMenu, value: item!.idMenu! }));
    } else return rootMenuOptions();
  }

  function rootMenuOptions(): SelectOptions[] {
    return controller.getMenuItems().map((item) => ({ label: item.nmMenu, value: item!.idMenu! }));
  }

  function handleClickPath(index: number) {
    const newPath = currentPath.slice(0, index);

    setCurrentPath(newPath);

    if (newPath.length) updateOptions(newPath[newPath.length - 1]);
    else {
      const currentOptions = controller.getMenuItems();
      setCurrentPathOptions(currentOptions || []);
    }
  }

  useEffect(() => {
    const fetchRootData = async () => {
      const menuData = await controller.findRootMenuData();

      setCurrentPathOptions(menuData);
    };

    fetchRootData();
  }, [formKey, controller]);

  const finalPath = form.values.idMenu ? currentPath : [...currentPath, { label: form.values.nmMenu, value: '' }];

  return (
    <Fragment>
      <Grid item xs={12} className={classes.container} style={{ marginBottom: 10, paddingTop: 0 }}>
        Caminho
      </Grid>
      <Grid item xs={3} className={classes.gridCell}>
        <Select<SelectOptions>
          disabled={!convertPathToSelectOptions().length}
          getOptionLabel={(opt) => opt.label}
          getOptionValue={(opt) => opt.value}
          label='Selecione o Caminho'
          placeholder='Selecione...'
          options={convertPathToSelectOptions()}
          onChangeValue={handleSelectPath}
        />
      </Grid>
      <Grid item xs={6} display={'flex'} alignItems={'center'} className={classes.switchContainer}>
        <CurrentPath handleClickModulePath={handleClickPath} path={finalPath} />
      </Grid>

      <Grid item xs={12} className={classes.container} style={{ marginBottom: 10 }}>
        Dados
      </Grid>
      <Grid item xs={3} className={classes.gridCell}>
        <TextField label='Nome' {...getFieldProps('nmMenu')} />
      </Grid>
      <Grid item xs={3} className={classes.gridCell}>
        <TextField label='Fonte React' {...getFieldProps('dsSource')} />
      </Grid>
      <Grid item xs={1} className={classes.gridCell}>
        <TextField type='number' label='Ordem' {...getFieldProps('nrOrdem')} />
      </Grid>
      <Grid item xs={3} className={classes.gridCell}>
        <TextField label='Ícone' {...getFieldProps('dsIcone')} />
      </Grid>
    </Fragment>
  );
}

export default MenuForm;
