import { SelectTable } from '@bubotech/sumora-react-components';
import ButtonFAB from '@bubotech/sumora-react-components/lib/buttonfab';
import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';
import { SelectTableTypes } from '@bubotech/sumora-react-components/lib/selecttable';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import { ArrowDownwardSharp, ArrowDropDown, Edit } from '@mui/icons-material';
import { Fragment, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import CardFilters from 'root-components/cardfilters/card-filters';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import Swal from 'root-components/swal/swal';
import SituacaoEnum from 'root-enumerations/situacao-enum';
import Busca from 'root-models/parametrosbusca';
import Resposta from 'root-models/respostaAPI';
import { MainStateType } from 'root-states';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import { DispatchAction } from 'root-states/root-dispatcher';
import { buildQuery } from 'root-utils/build-query';
import { useStyles } from '../../app.styles';
import { MenuItemProps } from '../models/menu-item';
import ConfigMenuAPI from '../resources/api';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { render } from 'react-dom';
export type ListingMenuPropType = {};
function ListingMenu(props: ListingMenuPropType) {
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const history = useNavigate();
  const classes = useStyles(props);

  const api = new ConfigMenuAPI();
  const [pageData, setPageData] = useState<Resposta>();
  const params = useRef<Busca>({ page: 1, orderField: 'nrOrdem', orderType: 'ASC' });
  const filtros = useSelector<MainStateType, any>((state) => state.cardFiltersReducer.data);

  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Menus');
    if (ScreensKeysEnum.EMPRESA in filtros) {
      const staticFields = buildQuery(filtros[ScreensKeysEnum.EMPRESA].filters, ['stResource, tpMethod'], []);
      params.current = { ...params.current, staticParams: staticFields, page: 1 };
    }
    loadData();
  });

  function loadData() {
    appLayoutActions.setLoading(true);

    api
      .findByPage(params.current)
      .then(res => {
        setPageData(res.data);
      })
      .catch(err => {
        setPageData(undefined);
        console.error(err);
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: 'Ocorreu um erro',
          text: 'Falha ao carregar os dados',
          icon: 'error'
        });
      })
      .finally(() => appLayoutActions.setLoading(false));
  }


  function renderAccordion(item: MenuItemProps, level: number) {
    return (
      <Accordion key={item.idMenu}>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div style={{ display: 'table', width: '50%', marginLeft: `${5 * level}%` }}>
            <div style={{ display: 'table-row' }}>
              <div style={{ display: 'table-cell', width: '40%' }}><b>Nome menu:</b> {item.nmMenu}</div>
              <div style={{ borderLeft: '2px solid black', paddingLeft: '1%', display: 'table-cell', width: '40%' }}><b>URL:</b> {item.dsSource}</div>
              <div style={{ borderLeft: '2px solid black', paddingLeft: '1%', display: 'table-cell' }}><b>Ordem:</b> {item.nrOrdem}</div>
            </div>
          </div>
        </AccordionSummary>
        {(item.menuList && item.menuList.length !== 0) && 
          <AccordionDetails>
            {item.menuList.map(subItem => renderAccordion(subItem, level + 1))}
          </AccordionDetails>
        }
      </Accordion>
    );
  }

  return (
    <main className={classes.rootListagem}>
      <div className={`${classes.tabContent} tabela`}>
        {
          pageData && pageData.data.map(item => renderAccordion(item, 0))
        }
      </div>

      <ButtonFAB onClick={() => history('/cadastros/desenvolvimento/menu/crud')}>
        <Edit />
      </ButtonFAB>
    </main>
  );
}

export default ListingMenu;